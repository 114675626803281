@charset "UTF-8";

#fb-editor {
    min-height: 100vh;
}
.back-grid {
    /*background-image: url("../images/grid9.png");*/
}

.form-wrap.form-builder .cb-wrap {
    width: 24%;
}
input.prof-pic-upld-ctrl {
    font-size: 16px;
}
.fb-render label {
    padding-bottom: 0.5rem;
}
.fb-render .form-control[type=file] {
    padding-top: 10px !important;
}
.fb-render .form-control, .fb-render .form-select {
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #7d8998;
    padding: 0px 10px !important;
    transition: border-color 0.25s ease 0s;
    height: 36px !important;
}
.rendered-form .form-group {
    margin-bottom: 16px;
}
.formbuilder-required {
    padding-left: 4px;
    color: #f00 !important;
}
.formbuilder-button {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
}
.formbuilder-button .btn {
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.formbuilder-button .btn-default {
    border: 2px solid #616161;
    color:#212121 ;
}
.formbuilder-header h1 {
    padding-top: 20px !important;
}
.formbuilder-header hr {
    margin-top: 30px;
}
.formbuilder-header {
    border-bottom: 1px solid #899094;
    padding-bottom: 8px;
    padding-top: 8px;
}
.formbuilder-paragraph {
    padding-top: 16px;
}
.form-wrap.form-builder .frmb-control li {
    border: 1px solid #757575;
    box-shadow: none;
    font-weight: 400;
    padding-left: 20px;
    background: transparent;
}
.form-wrap.form-builder .frmb-control li:hover {
    /*color: grey;*/
    background: lightgrey;
    color: #161616;
}
.form-wrap.form-builder .frmb-control li span {
    font-weight: 400;
}
.fb-render .section-group {
    font-size: 1.875rem;
}
.template-name, .template-design {
    margin-bottom: 0.3rem;
    line-height: 27px;
    font-size: 16px;
    font-weight: normal;
}
.template-design {
    padding-bottom: 12px;
}
.template-desc {
    padding-top: 16px;
    padding-bottom: 0.3rem;
}
#txtTemplateDesc {
    min-height: 120px;
}
form-designer .section-group {
    font-size: 20px;
    font-weight: 600;
    color: #011936;
    padding-bottom: 8px;
}
.form-wrap.form-builder .form-control {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #ffffff;
    background-clip: border-box;
    padding: 2px 12px !important;
    transition: border-color 0.25s ease 0s;
    height: 40px;
    border: 1px solid #888C8C;
}
.form-wrap.form-builder .frmb li.form-field {
    padding: 0px;
    margin-bottom: 0px;
    background: transparent;
}
.form-wrap.form-builder .frmb .form-elements {
    background: transparent;
}
.form-wrap.form-builder .frmb .field-label {
    color: unset;
    margin-bottom: 0.3rem;
    line-height: 27px;
    font-size: 16px;
    font-weight: normal;
    padding-top: 16px;
}
.field-chkAcctType-preview .checkbox-group {
    max-height: 200px;
    overflow-y: scroll;
    padding-left: 16px;
    border: 1px solid rgba(0, 0, 0, 0.7);
}
.fb-render-preview .field-chkAcctType {
    max-height: 200px;
    overflow-y: scroll;
    margin-bottom: 16px;
    padding-left: 16px;
    border: 1px solid #7d8998;
}

.fb-render-preview label {
    padding-bottom: 12px;
    color: unset !important;
}

.form-designer .formbuilder-checkbox {
    padding-left: 12px;
}
.navbar-dropdown {
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.45s ease;
    z-index: 1030;
    background: #282828;
}

    .navbar-dropdown .navbar-logo {
        margin-right: 0.7rem;
        transition: margin 0.3s ease-in-out;
        vertical-align: middle;
    }

    .navbar-dropdown .navbar-caption {
        font-weight: 700;
        white-space: normal;
        vertical-align: -4px;
        line-height: 3.125rem !important;
    }

    .navbar-dropdown.navbar-fixed-top {
        position: fixed;
    }

    .navbar-dropdown .navbar-brand span {
        vertical-align: -4px;
    }

    .navbar-dropdown.bg-color.transparent {
        background: none;
    }

    .navbar-dropdown .navbar-close {
        left: 0.6875rem;
        position: fixed;
        top: 0.75rem;
        z-index: 1000;
    }

    .navbar-dropdown .hamburger-icon {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        -webkit-box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828;
        -moz-box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828;
        box-shadow: 0 -6px 0 1px #282828,0 0 0 1px #282828,0 6px 0 1px #282828;
    }

.dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    border-bottom: 0.35em solid transparent;
    border-left: 0.35em solid;
    border-right: 0;
    border-top: 0.35em solid transparent;
    margin-left: 0.3rem;
}

.dropdown-menu .dropdown-item:focus {
    outline: 0;
}

.nav-dropdown {
    font-size: 0.75rem;
    font-weight: 500;
    height: auto !important;
}

    .nav-dropdown .nav-btn {
        padding-left: 1rem;
    }

    .nav-dropdown .link {
        margin: .667em 1.667em;
        padding: 0;
        transition: color .2s ease-in-out;
    }

        .nav-dropdown .link.dropdown-toggle {
            margin-right: 2.583em;
        }

            .nav-dropdown .link.dropdown-toggle::after {
                margin-left: .25rem;
                border-top: 0.35em solid;
                border-right: 0.35em solid transparent;
                border-left: 0.35em solid transparent;
                border-bottom: 0;
            }

            .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
                margin: 0;
                padding: 0.667em 3.263em 0.667em 1.667em;
            }

        .nav-dropdown .link::after,
        .nav-dropdown .dropdown-item::after {
            color: inherit;
        }

    .nav-dropdown .dropdown-menu {
        border-radius: 0;
        border: 0;
        left: 0;
        margin: 0;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
        position: relative;
    }

    .nav-dropdown .dropdown-submenu {
        margin-left: 0.125rem;
        top: 0;
    }

    .nav-dropdown .dropdown-item {
        font-weight: 500;
        line-height: 2;
        padding: 0.3846em 4.615em 0.3846em 1.5385em;
        position: relative;
        transition: color .2s ease-in-out, background-color .2s ease-in-out;
    }

        .nav-dropdown .dropdown-item::after {
            margin-top: -0.3077em;
            position: absolute;
            right: 1.1538em;
            top: 50%;
        }

        .nav-dropdown .dropdown-item:focus, .nav-dropdown .dropdown-item:hover {
            background: none;
        }

section {
    background-color: #ffffff;
}

body {
    font-style: normal;
    line-height: 1.5;
    font-weight: 400;
    color: #07122b; /*#07122b; #4c4c4c; #374e5a; #0b3047;*/
    position: relative;
}

button {
    background-color: transparent;
    border-color: transparent;
}

section,
.container,
.container-fluid {
    position: relative;
    word-wrap: break-word;
}

.article .lead p,
.article .lead ul,
.article .lead ol,
.article .lead pre,
.article .lead blockquote {
    margin-bottom: 0;
}

a {
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

    a:hover {
        text-decoration: underline !important;
    }

.aob-section-title {
    font-style: normal;
    line-height: 1.3;
}

.aob-section-subtitle {
    line-height: 1.3;
}

.aob-text {
    font-style: normal;
    line-height: 1.7;
}

b,
strong {
    font-weight: bold;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    transition-delay: 9999s;
    -webkit-transition-property: background-color, color;
    transition-property: background-color, color;
}

textarea[type=hidden] {
    display: none;
}

section {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.hidden {
    visibility: hidden;
}

img,
iframe {
    display: block;
    width: 100%;
}

.card {
    background-color: transparent;
    border: none;
}

.card-box {
    width: 100%;
}

.card-img {
    text-align: center;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
}

    .card-img img {
        width: 100%;
    }

.aob-section-btn {
    margin-left: -0.6rem;
    margin-right: -0.6rem;
}

.btn-form {
    margin: 0;
}

    .btn-form:hover {
        cursor: pointer;
    }

nav .aob-section-btn {
    margin-left: 0rem;
    margin-right: 0rem;
}

[type=submit] {
    -webkit-appearance: none;
}

.bg-gradient {
    background-size: 200% 200%;
    animation: gradient-animation 5s infinite alternate;
    -webkit-animation: gradient-animation 5s infinite alternate;
}

.menu .navbar-brand {
    display: -webkit-flex;
}

    .menu .navbar-brand span {
        display: flex;
        display: -webkit-flex;
    }

    .menu .navbar-brand .navbar-caption-wrap {
        display: -webkit-flex;
    }


@media (min-width: 768px) and (max-width: 991px) {
    .menu .navbar-toggleable-sm .navbar-nav {
        display: -ms-flexbox;
    }
}

@media (max-width: 991px) {
    .menu .navbar-collapse {
        max-height: 93.5vh;
    }

        .menu .navbar-collapse.show {
            overflow: auto;
        }
}

@media (min-width: 992px) {
    .menu .navbar-nav.nav-dropdown {
        display: -webkit-flex;
    }

    .menu .navbar-toggleable-sm .navbar-collapse {
        display: -webkit-flex !important;
    }

    .menu .collapsed .navbar-collapse {
        max-height: 93.5vh;
    }

        .menu .collapsed .navbar-collapse.show {
            overflow: auto;
        }
}

@media (max-width: 767px) {
    .menu .navbar-collapse {
        max-height: 80vh;
    }
}

.navbar {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
}

.navbar-collapse {
    -webkit-flex-basis: 100%;
    -webkit-flex-grow: 1;
    -webkit-align-items: center;
}

.nav-dropdown .link {
    padding: 0.667em 1.667em !important;
    margin: 0 !important;
}

.nav {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
}

.row {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
}

.justify-content-center {
    -webkit-justify-content: center;
}

.form-inline {
    display: -webkit-flex;
}

.card-wrapper {
    -webkit-flex: 1;
}

.carousel-control {
    z-index: 10;
    display: -webkit-flex;
}

.carousel-controls {
    display: -webkit-flex;
}

.form-group:focus {
    outline: none;
}

input[type=range] {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.carousel-item {
    text-align: center;
}

    .carousel-item img {
        margin: auto;
    }

.navbar-toggler {
    align-self: flex-start;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

    .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none;
        box-shadow: none;
    }

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

.navbar-toggler-left {
    position: absolute;
    left: 1rem;
}

.navbar-toggler-right {
    position: absolute;
    right: 1rem;
}

.card-img {
    width: auto;
}

.menu .navbar.collapsed:not(.beta-menu) {
    flex-direction: column;
}

html,
body {
    font-size: 16px;
    height: auto;
    min-height: 100vh;
    background: #4C4C4C;
}

.dropup .dropdown-toggle::after {
    display: none;
}

.form-asterisk {
    font-family: initial;
    position: absolute;
    top: -2px;
    font-weight: normal;
}

.form-control-label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0.357em;
    padding: 0;
}


.form-active .form-control:invalid {
    border-color: red;
}

form .row {
    margin-left: -0.6rem;
    margin-right: -0.6rem;
}

    form .row [class*=col] {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }

form .aob-section-btn {
    margin: 0;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

form .btn {
    display: flex;
    margin: 0;
}

form .form-check-input {
    margin-top: 0.5;
}

textarea.form-control {
    line-height: 1.5rem !important;
}

.form-group {
    margin-bottom: 1.2rem;
}

form .btn {
    min-height: 48px;
}

    .form-control:focus {
        box-shadow: none;
    }

:focus {
    outline: none;
}

ul,
ol,
pre,
blockquote {
    margin-bottom: 2.3125rem;
}

.mt-4 {
    margin-top: 2rem !important;
}

.mb-4 {
    margin-bottom: 2rem !important;
}

@media (min-width: 992px) {
    .container {
    }

    .row {
    }

    .row > [class*=col] {
    }
}

@media (min-width: 768px) {
    .container-fluid {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.card-wrapper,
.item-wrapper {
    overflow: hidden;
}

.item {
    position: relative;
}

.dropdown-menu .dropdown-menu {
    left: 100%;
}

.dropdown-item + .dropdown-menu {
    display: none;
}

.dropdown-item:hover + .dropdown-menu,
.dropdown-menu:hover {
    display: block;
}

body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.section-group {
    font-size: 2rem;
    line-height: 1.35;
    font-weight: 500;
    padding-top: 10px;
}



@media (max-width: 992px) {
    .display-1 {
        font-size: 3.68rem;
    }
}

.form-control {
    font-size: 1rem !important;
    line-height: 1.5;
    font-weight: 400;
}

.form-control:hover,
.form-control:focus {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
    border-color: #6592e6 !important;
}

    .form-control:-webkit-input-placeholder {
        font-size: 1rem;
        line-height: 1.5;
    }
input::placeholder {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
}

blockquote {
    border-color: #6592e6;
}

body {
    overflow-x: hidden;
}

a {
    transition: color 0.6s;
}

.alert-danger {
    line-height: 1.3;
    background-color: rgba(255,42,81,.1);
    border: 1px solid #cdcdcd;
    border-left: 5px solid #ff2a51;
    color: #f00;
    font-size: 1.1rem;
}
.alert-info {
    line-height: 1.3;
    border: 1px solid #cdcdcd !important;
    font-size: 1.1rem;
}
.alert {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
}

._loading_overlay_content {
    top: 45%;
    left: 35%;
    position: fixed;
}

..numberCircle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 6px 8px 0px;
    background: #fff;
    border: 3px solid #235ec9;
    color: black;
    text-align: center;
    font: 32px Arial, sans-serif;
    display: inline-block;
}

.card-wrapper-lightgray-bg {
    background: #fafafa;
}



.disabled {
    opacity: 0.4 !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

.step-number-active::before {
    content: "";
    width: 130%;
    height: 130%;
    position: absolute;
    border-radius: 50%;
    background-color: transparent;
    border: 0.125rem solid #f5f5f5;
}


.app-sec-nav .step-number, .step-number-active {
    background-color: #f5f5f5;
    transition: all .15s linear;
    justify-content: center;
    line-height: inherit;
    align-items: center;
    color: #ADADAD;
    position: absolute;
    border-radius: 50%;
    font-weight: 700;
    display: flex;
    height: 2.1rem;
    width: 2.1rem;
    font-weight: 700;
    /*margin-left: 76px;*/
}
.app-sec-nav .step-number-active {
    background-color: #00398F;
    color: #ffffff;
    margin-top: -4px;
}



.formbuilder-select-label {
}
.rendered-form-acct label {
    font-size: 1.38rem;
    line-height: 1.5;
    padding-bottom: 0.5rem !important;
    color: #333333;
}

.rendered-form-acct .form-group {
    padding-bottom: 1rem !important;
}
.form-control {
    /*background-color: #ffffff;*/
    background-clip: border-box;
    /*color: rgba(0, 0, 0, 0.87) !important;*/
    /*line-height: 1rem !important;*/
    /*height: auto;*/
    padding: 0px 10px 3px 10px !important;
    transition: border-color 0.25s ease 0s;
    height: 38px;
    border: 1px solid #9e9e9e !important;
    /*border-radius: 4px;*/
    /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;*/
}

.form-select {
    color: #333333 !important;
    font-size: 1rem !important;
    background-color: #ffffff;
    /*color: rgba(0, 0, 0, 0.87) !important;*/
    /*line-height: 1rem !important;*/
    height: 38px;
    /*padding: 0.9rem 1rem;*/
    transition: border-color 0.25s ease 0s;
    border: 1px solid #9e9e9e !important;
    padding: 0px 10px 0px 10px;
    /*border-radius: 8px;*/
    /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;*/
}
.form-label {
    margin-bottom: 5px;
    /*color: #333333;*/
    font-weight: normal !important;
    font-size: 16px;
}
.rendered-form-acct .btn {
    margin-left: 0px !important;
}
.rendered-form-acct {
    min-height: 300px;
    border-left: 1px solid #C2C2C2;
}
.form-group .formbuilder-required {
    color: #f00 !important;
    padding-left: 5px;
}
.rendered-form-acct .tooltip-element {
    background: #00f;
    margin-bottom: 5px !important;
}
.rendered-form .tooltip-element {
    background: #002d72 !important;
    padding-bottom: 5px;
}

.form-control:disabled, .form-control[readonly] {
    background-color: rgba(204, 204, 204, 0.8);
    color: #7A7A7A;
}

.form-select:disabled, .form-select[readonly] {
    background-color: rgba(204, 204, 204, 0.8);
    color: #7A7A7A;
}

.prof-pic {
    width: 150px !important;
    display: inline-block;
}
.prof-pic-prvw {
    max-width: 170px !important;
    display: inline-block;
}
.navmenusec .dropdown-menu {
    padding: 0 !important;
}
.rounded-circle {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.avatar-circle {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    background-color: #f00;
}

input.num_no_arrow::-webkit-outer-spin-button,
input.num_no_arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.num_no_arrow[type=number] {
    -moz-appearance: textfield;
}

.formbuilder-radio-inline label {
    padding-left: 5px;
}
button:disabled,
button[disabled] {
    opacity: 0.85;
    cursor: not-allowed;
    color: #A3A3A3 !important;
}
input:-internal-autofill-selected {
    background-color: rgb(255, 255, 255) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}
.app-sec-nav .text-box,
.app-sec-nav .text-box-active {
    /*padding-left: 25px;*/
    padding-bottom: 3.5rem;
}

.app-sec-nav .text-box {
    border-left: 2px solid #ccc;
}

.app-sec-nav .text-box-active {
    border-left: 2px solid #007db8;
}
.display-19 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    line-height: 1.5;
    color: #333333;
}
.text-danger {
    margin-bottom: 0px;
}