body {
}

.fb-render {
    margin: auto;
   /* max-width: 1280px*/
}

/*
.formbuilder-text-label {
    text-align: right;
}
*/
/*.text-right {*/
    /*float: right;*/
    /*text-align:right;
    background-color: slategray;
    padding-right: 50%;
    margin: auto;
    max-width: 1280px;
}*/