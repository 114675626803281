body {
}

.form-rendered #fb-editor {
    display: none;
}

.render-wrap {
    display: none;
}

.form-rendered .render-wrap {
    display: block;
}

#edit-form {
    display: none;
    float: right;
}

.form-rendered #edit-form {
    display: block;
}

/* Darker background on mouse-over */
.btn:hover {
    background-color: lightgrey;
    color:white;
}
/*.formbuilder-text-label {
    text-align: right;
    background-color: slategray;
}*/
.text-right-custom {
    text-align: right;
   /* float: right;
    background-color: aqua;
    padding-right: 50%;
    margin: auto;
    max-width: 1280px;*/
}